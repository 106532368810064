.chapter-image-block{
    .image-text{
        width: 100% !important;
        min-height: 78px;
        height: auto;
        position: relative;
        padding-left: 100px;
        margin-top: 10px !important;
        div{
            background-color: $grey-d5;
            height: 100%;
            width: 80px;
            color: $white;
            font-size: 18px;
            position: absolute;
            left: 0;
            top: 0;
        }
        p{
            font-size: 14px !important;
            padding-top: 12px;
            padding-bottom: 12px;
            line-height: 21px !important;
            font-weight: 400;
        }
    }
}