
@mixin block-intro {
    @include max-width;
    margin: 60px auto;
    @include media-breakpoint-down(sm) {
        margin: 48px auto 48px;
    }
    .title {
        padding-top: 40px;
        @include media-breakpoint-down(sm) {
            padding-top: 32px;
        }
    }
    .description {
        font-size: 18px;
        line-height: 24px;
        width: 660px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

@mixin max-width {
    max-width: 1040px;
    margin: 0px auto;
    @include media-breakpoint-down(lg) {
        width: calc(100% - 250px);
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 120px);
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% - 64px);
    }
}

@mixin max-width-small {
    max-width: 750px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
        width: calc(100% - 250px);
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 120px);
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% - 64px);
    }
}