.sign-list-block, .sign-list-text-block{
    .sign{
        margin-bottom: 90px;
        @include media-breakpoint-down(md){
            margin-bottom: 45px;
        }
        .img-container{
            margin-right: 90px;
            position: relative;
            width:100px;
            &::after{
                content: '';
                position: absolute;
                top: 50%;
                height: 1px;
                width: 40px;
                background-color: $grey-d2;
                right: calc(-100% + 20px);
            }
            @include media-breakpoint-down(md){
                margin-bottom: 30px;
                &::after{
                    content: none;
                }
            }
        }
        h3{
            font-weight: 500 !important;
            @include media-breakpoint-down(md){
                font-weight: 700 !important;
            }
        }
    }
}
.sign-list-block{
    .img-container{
        @include media-breakpoint-down(md){
            margin-right: 34px !important;
            margin-bottom: 0 !important;
            img{
                width: 70px;
            }
        }
    }
    h3{
        margin-left: 20px;
        max-width: calc(100% - 210px);
        @include media-breakpoint-down(md){
            margin-left: 0;
            max-width: auto;
        }
    }
}
.sign-list-text-block{
    .sign{
        @include media-breakpoint-down(lg){
            flex-wrap: wrap;
            margin-bottom: 45px;
        }
    }
    .img-container{
        @include media-breakpoint-down(lg){
            min-width: 100%;
            margin-bottom: 30px;
            &::after{
                content: none !important;
            }
        }
    }
    .text-container{
        padding-left: 20px;
        @include media-breakpoint-down(lg){
            padding-left: 0;
            ul{
                li{
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
}