.instructor-modal {
    .slick-slider {
        width: 80%;
        margin: auto;
        .slick-arrow {
            border: solid black;
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 3px;
            &.slick-next {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                &:before{
                    content: none
                }
            }
            &.slick-prev {
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
                &:before{
                    content: none
                }
            }
        }
        img {
            margin: auto;
        }
    }
}