html{

}
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Public Sans', sans-serif;
    color: $font-color;
    min-height: 100vh;
    padding-bottom: 275px;
    position: relative;
    padding-top: 200px;
    @include media-breakpoint-down(md){
        padding-bottom: 430px;
        padding-top: 100px;
    }
    @include media-breakpoint-down(sm){
        padding-bottom: 405px;
    }
    &.pt-100{
        padding-top: 100px;
    }
    &.down{
        .top-container{
            top: 220px !important;
            transition: top .3s linear;
            @include media-breakpoint-down(md){
                top: 190px !important;
            }
            @include media-breakpoint-down(sm){
                top: 90px !important;
            }
        }
        thead{
            top: 340px !important;
            transition: top .3s linear;   
            @include media-breakpoint-down(xs){
                top: 358px !important;
            }
        }
    }
    &.hamburger-open{
        @include media-breakpoint-down(md){
            position: fixed;
        }
    }
    &.page-homepage{
        padding-top: 100px !important;
    }
}
section{
    @include media-breakpoint-down(md){
        overflow: hidden;
    }
}
    
p{
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(24px + (28 - 24) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    a{
        color: $darkblue;
        font-style: normal;
        &:hover{
            text-decoration: none;
        }
    }
}
h1{
    font-size: calc(26px + (40 - 26) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(32px + (50 - 32) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 680;
    @media (min-width: 1920px){
        font-size: 40px;
        line-height: 50px;
    }
}
h2{
    font-size: calc(22px + (32 - 22) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(30px + (42 - 30) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 300;
    font-style: normal;
    @media (min-width: 1920px){
        font-size: 32px;
        line-height: 42px;
    }
}
h3{
    font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(22px + (26 - 22) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    @media (min-width: 1920px){
        font-size: 24px;
        line-height: 26px;
    }
}
button, select{
    &:active, &:focus{
        outline: none;
        box-shadow: none;
    }
}
.btn{
    border: 1px solid $grey;
    height: 46px;
    border-radius: 22.5px;
    padding: 0 28px;
    color: $darkblue;
    line-height: 44px;
    font-weight: 600;
    margin-top: 30px;
    &:active, &:focus{
        outline: none;
        box-shadow: none;
    }
    &:hover{
        background-color: $darkblue;
        color: $white;
    }
    &.border-green{
        border-color: $green;
        &:hover{
            background-color: $green;
            color: $white;
        }
    }
    &.border-blue{
        border-color: $blue;
        &:hover{
            background-color: $blue;
            color: $white;
        }
    }
    &.border-darkblue{
        border-color: $darkblue;
        &:hover{
            background-color: $darkblue;
            color: $white;
        }
    }
}
form{
    .login_errors{
        margin-bottom: 30px;
        color: $red;
    }
}
.page-examindexpage, .page-exampage, .page-instructorpage, .page-classpage{
    padding-top: 220px !important;
    @include media-breakpoint-down(sm){
        padding-top: 100px !important;
    }
    .subnav{
        svg{
            margin-right: 12px;
            margin-top: -10px;
        }
        h3{
            font-weight: 600;
            color: $white;
            margin-bottom: 0;
        }
    }
}
.page-standardindexpage, .page-standardpage, .page-classindexpage, .page-classpage, .page-moduleitempage{
    padding-top: 220px !important;
    @include media-breakpoint-down(sm){
        padding-top: 100px !important;
    }
    h2{
        font-weight: 680 !important;
    }
    h3{
        font-weight: 580 !important;
    }
    .subnav{
        background-color: $darkblue;
        @include media-breakpoint-down(sm){
            height: auto;
            padding: 26px 0;
        }
        ul{
            @include media-breakpoint-down(sm){
                flex-wrap: wrap;
                margin-top: 0;
                padding-right: 0;
            }
            li{
                cursor: pointer;
                list-style: none;
                margin-right: 32px;
                font-weight: 400;
                margin-bottom: 0;
                @include media-breakpoint-down(sm){
                    line-height: 11px;
                    margin-top: 16px;
                    margin-right: 10px;
                    &::before{
                        content: none;
                    }
                }
                &.active{
                    position: relative;
                    font-weight: 680;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        background-color: $white;
                        left: calc(50% - 4px);
                        bottom: -14px;
                        border-radius: 50%;
                        @include media-breakpoint-down(sm){
                            width: 5px;
                            height: 5px;
                            bottom: -10px;
                        }
                    }
                }
                &.title{
                    margin-bottom: 0;
                    margin-right: 60px;
                    @include media-breakpoint-down(sm){
                        width: 100%;
                        margin-top: 0;
                        margin-bottom: 10px;
                    }
                    a{
                        font-size: 24px !important;
                        font-weight: 600;
                        &:hover{
                            border-bottom: none !important;
                        }
                    }
                }
                a{
                    color: $white;
                    font-size: 18px !important;
                    text-decoration: none;
                    &:hover{
                        border-bottom: 1px solid $white;
                    }
                    @include media-breakpoint-down(md){
                        font-size: 16px !important;
                    }
                    @include media-breakpoint-down(sm){
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}
.image-text{
    height: 70px;
    font-size: 14px;
    color: $font-color;
    font-weight: 600;
    padding-left: 27px;
    line-height: 64px;
    width: 87.5%;
    margin: 0 auto;
}
.subnav{
    height: 100px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        bottom: -9px;
        left: 0;
        width: 100%;
        height: 9px;
        background-image: linear-gradient(135deg, $grey-d3 12.50%, transparent 12.50%, transparent 50%, $grey-d3 50%, $grey-d3 62.50%, transparent 62.50%, transparent 100%);
        background-size: 4px 4px;
    }
}
.no-access{
    .modal-title{
        margin: 30px 0;
    }
}
ul{
    margin: 20px 0;
    li{
        line-height: 32px;
        font-size: 20px;
        list-style: none;
        position: relative;
        margin-bottom: 10px;
        &::before{
            content: '';
            position: absolute;
            top: 10px;
            left: - 20px;
            background-color: $darkblue;
            height: 8px;
            width: 8px;
            border-radius: 50%;
        }
    }
}
.bg-darkblue {
    background-color: $darkblue;
}
.bg-blue {
    background-color: $blue;
}
.bg-green {
    background-color: $green;
}
.striped-bg{
    background-image: linear-gradient(135deg, $grey-d3 12.50%, transparent 12.50%, transparent 50%, $grey-d3 50%, $grey-d3 62.50%, transparent 62.50%, transparent 100%);
    background-size: 4px 4px;
}
.pt-70{
    padding-top: 70px;
    @include media-breakpoint-down(md){
        padding-top: 120px;
    }
}