.paragraph-block{
    position: relative;
    margin-bottom: 40px;
    H2, H3{
        margin-top: 30px;
    }
    p{
        margin-bottom: 30px;
    }
    p.right-p{
        max-width: 50%;
        margin-bottom: 50px;
        @include media-breakpoint-down(sm){
            max-width: 100%;
        }
    }
    img{
        height: auto;
        object-fit: contain;
        border-radius: 8px; 
        max-width: 100%;
        @include media-breakpoint-down(sm){
            margin: 20px auto;
        }
        &.right{
            margin: 0 -12% 20px 30px;
            float: right;
            @media (max-width: 1200px){
                margin: 0 -40% 20px 30px;
            }
            @include media-breakpoint-down(md){
                margin: 20px 0 20px 30px;
            }
            @include media-breakpoint-down(sm){
                float: unset;
            }
        }
        &.left{
            float: left;
            margin: 0 30px 20px -12%;
            @media (max-width: 1200px){
                margin: 0 30px 20px -40%;
            }
            @include media-breakpoint-down(md){
                margin: 30px 20px 20px 0;
            }
            @include media-breakpoint-down(sm){
                float: unset;
            }
        }
    }
    b{
        font-weight: 900 !important;
        color: #565656 !important;
    }
    ul{
        margin: 20px 0;
        li{
            line-height: 32px;
            font-size: 20px;
            list-style: none;
            position: relative;
            display: table;
            margin-bottom: 10px;
            &::before{
                content: '';
                position: absolute;
                top: 10px;
                left: - 20px;
                background-color: $darkblue;
                height: 8px;
                width: 8px;
                border-radius: 50%;
            }
        }
    }
}