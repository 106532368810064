.page-instructorpage{
    .subnav{
        svg{
            width: 32px;
            transform: rotate(-90deg);
            margin-top: -3px;
        }
    }
    .top-container{
        padding-top: 30px;
        padding-bottom: 30px;
        @include media-breakpoint-down(sm){
            padding-top: 0;
            margin-top: -20px;
        }
        &.fixed{
            position: fixed;
            top: 0;
            background-color: $white;
            width: 60.5%;
            z-index: 1;
            transition: top .3s linear;
            + .inner{
                padding-top: 196px;
                @include media-breakpoint-down(sm){
                    padding-top: 290px;
                }
                @include media-breakpoint-down(xs){
                    padding-top: 366px;
                }
            }
            @include media-breakpoint-down(lg){
                width: 74%;
            }
            @include media-breakpoint-down(md){
                width: 92%;
                left: 4%;
            }
            @include media-breakpoint-down(sm){
                top: -17%;
                z-index: 1;
            }
            @include media-breakpoint-down(xs){
                top: -25%;
                width: 88%;
                left: 6.5%;
            }
        }
        form{
            border-bottom: 1px solid $blue;
            margin-left: auto;
            @include media-breakpoint-down(md){
                width: 100%;
                margin-top: 30px;
            }
            input{
                height: 70%;
                border: none;
                background-color: transparent;
                width: 300px;
                &:focus, &:active{
                    outline: none;
                    box-shadow: none;
                }
                @include media-breakpoint-down(md){
                    width: 88%;
                    height: 46px;
                }
            }
            svg{
                width: 36px;
                height: 40px;
            }
        }
        select{
            border: none;
            background-color: transparent;
            -webkit-appearance: none;
        }
        .filter-container{
            min-width: 135px;
            @include media-breakpoint-up(md){
                margin-right: 30px;
            }
            .filter{
                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    top: 42px;
                    right: 12px;
                    width: 8px;
                    height: 8px;
                    border: 2px solid $font-color;
                    border-left: 0;
                    border-top: 0;
                    transform: rotate(45deg);
                    @include media-breakpoint-down(md){
                        top: 46px;
                        right: 18px;
                        width: 10px;
                        height: 10px;
                    }
                }
                @include media-breakpoint-down(md){
                    margin-top: 30px;
                }
            }
            select{
                background-color: $grey-l1;
                padding: 4px;
                border-radius: 3px;
                cursor: pointer;
                @include media-breakpoint-down(md){
                    height: 41px;
                }
            }
        }
    }
    .table-container{
        table{
            margin-bottom: 60px;
            thead{
                z-index: -1;
                position: relative;
                &.fixed-header{
                    position: fixed;
                    top: 100px;
                    width: 61.5%;
                    background-color: $white;
                    z-index: 1;
                    padding-top: 20px;
                    padding-bottom: 10px;
                    transition: top .3s linear;
                    @include media-breakpoint-down(lg){
                        width: 74%;
                    }
                    @include media-breakpoint-down(md){
                        width: 92%;
                        left: 4%;
                        top: 140px;
                    }
                    @include media-breakpoint-down(sm){
                        top: 12%;
                        padding-top: 0;
                        width: 88%;
                        left: 6.5%;
                    }
                    tr{
                        min-width: 100%;
                        display: flex;
                        align-items: center;
                        th{
                            &.name{
                                width: 37%;
                                @include media-breakpoint-down(sm){
                                    padding-left: 14px;
                                }
                            }
                            &.living{
                                width: 7%;
                            }
                            &.location{
                                width: 18%;
                            }
                            &.phone{
                                width: 12%;
                            }
                            &.w-6{
                                width: 6%;
                                @include media-breakpoint-down(sm){
                                    width: 15%;
                                }
                            }
                        }
                    }
                }
                tr{
                    th{
                        border: none;
                        padding-bottom: 2px;
                        font-size: 14px;
                        font-weight: 400;
                        position: relative;
                        &:first-child{
                            padding-left: 60px;
                            @include media-breakpoint-down(md){
                                padding-left: 0;
                                font-size: 14px;
                            }
                        }
                        &:hover{
                            cursor: pointer;
                            .tooltiptext {
                                visibility: visible;
                                position: absolute;
                                left: 0;
                                top: -14px;
                            }
                        }
                        svg{
                            max-width: 40px;
                        }
                        .tooltiptext {
                            visibility: hidden;
                            width: 120px;
                            background-color: black;
                            color: #fff;
                            text-align: center;
                            border-radius: 6px;
                            padding: 5px 0;
                            /* Position the tooltip */
                            position: absolute;
                            z-index: 1;
                        }
                    }
                        
                }
            }
            tr{
                height: 47px;
                td{
                    &:first-child{
                        padding-left: 60px;
                        @include media-breakpoint-down(md){
                            padding-left: 0;
                            font-size: 14px;
                            color: $darkblue;
                            text-decoration: underline;
                        }
                    }
                    &.name:hover {
                        cursor: pointer;
                    }
                }
                &.first-row{
                    height: 57px;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background-color: $blue;
                        bottom: 0;
                        left: 0;
                    }
                    @include media-breakpoint-down(md){
                        height: 30px;    
                    }
                    td{
                        border-bottom: 1px solid $blue;
                        border-top: none;
                        padding: 2px !important;
                        vertical-align: bottom;
                        font-size: calc(13px + (16 - 13) * ((100vw - 300px) / (1920 - 300)));
                        text-decoration: none;
                        h3{
                            color: $font-color;
                            margin-bottom: 0;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .instructor-modal {
        @include media-breakpoint-down(sm){
            height: 100vh;
            background-color: $white;
            width: 100vw;
            .modal-dialog{
                height: 100%;
                .modal-content{
                    height: 100%;
                    border: none !important;
                }
            }
        }
        .modal-body{
            .info{
                min-width: 115px;
            }
            table{
                width: 100%;
                margin-top: 30px;
                tr {
                    td:first-child, th:first-child{
                        @include media-breakpoint-up(md){
                            padding-left: 0.75rem !important;
                        }
                    }
                    td{
                        .tooltiptext{
                            min-width: 115px;
                            @include media-breakpoint-up(md){
                                margin-bottom: 10px;
                            }
                        }
                        svg {
                            margin-right: 12px;
                            @include media-breakpoint-down(md){
                                margin-top: 14px;
                                &:first-child{
                                    margin-right: 20px;
                                    width: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.modal-backdrop{
    @include media-breakpoint-down(sm){
        display: none !important;
    }
}