.login.modal, .user-profile{
    .login-container{
        background-color: $white;
        padding-top: 30px;
        border-radius: 8px;
        width: 90%;
        margin: 50px auto;
        max-width: 800px;
        position: relative;
        .close{
            position: absolute;
            width: 40px;
            height: 40px;
            right: 20px;
            cursor: pointer;
            top: 20px;
            .close-container{
                .line{
                    width: 33px;
                    height: 2px;
                    border-radius: 4px;
                    background-color: $font-color;
                    &:first-child{
                        transform: rotate(45deg);
                    }
                    &:last-child{
                        transform: rotate(-45deg) translate(0px, -2px);
                    }
                }
            }
        }
        svg{
            margin-bottom: 12px;
            width: 40px;
            height: 40px;
            g>g{
                fill: $darkblue !important;
            }
        }
        p{
            font-size: 20px;
        }
        form{
            padding: 30px;
            .input-container{
                margin-bottom: 30px;
                label{
                    transform: translateY(-36px);
                    transition: transform .2s linear;
                    z-index: 0;
                }
                input{
                    width: 100%;
                    height: 50px;
                    border: none;
                    z-index: 1;
                    background-color: transparent;
                    border-bottom: 2px solid $darkblue;
                    &:active, &:focus, &:valid{
                        box-shadow: none;
                        outline: none;
                        + label{
                            transform: translateY(-70px);
                            transition: transform .2s linear;
                        }
                    }
                }
            }
            .pass-reset{
                margin-bottom: 30px;
                margin-top: -20px;
            }
        }
    }
}
