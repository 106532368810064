.page-homepage{
    .toggler-col{
        display: none;
    }
    .card-container{
        padding-top: 60px;
        padding-bottom: 86px;
        .cards{
            &:nth-child(n+4){
                .card{
                    background-color: $white;
                    h1{
                        margin-top: 30px;
                    }
                    h1, span{
                        color: $font-color !important;
                    }
                }
            }
        }
        a{
            text-decoration: none;
            .card{
                min-height: 270px;
                margin-bottom: 34px;
                padding-left: 40px;
                border-radius: 8px;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
                @include media-breakpoint-down(md){
                    margin-bottom: 15px;
                    min-height: 200px;
                }
                svg{
                    margin-bottom: 30px;
                }
                h1{
                    color: $white;
                    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
                    line-height: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
                    font-style: normal;
                    font-weight: 680;
                    @media (min-width: 1920px){
                        font-size: 24px !important;
                        line-height: 24px !important;
                    }
                }
                span{
                    color: $white;
                    font-style: italic;
                    font-weight: 300;
                }
            }
        }
    }
    .news-container{
        padding-top: 128px;
        padding-bottom: 124px;
        @include media-breakpoint-down(md){
            padding-top: 80px;
            padding-bottom: 80px;
        }
        h2{
            font-size: calc(26px + (30 - 26) * ((100vw - 300px) / (1920 - 300))) !important;
            line-height: calc(26px + (30 - 26) * ((100vw - 300px) / (1920 - 300))) !important;
            font-style: normal;
            font-weight: 600;
            margin-bottom: 50px;
            @include media-breakpoint-down(md){
                margin-bottom: 20px;
            }
        }
        .news-title{
            font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
            font-style: normal;
            line-height: calc(26px + (30 - 26) * ((100vw - 300px) / (1920 - 300)));
            font-weight: 600;
            display: block;
            margin-bottom: 10px;
            a{
                color: $font-color;
                &:hover{
                    text-decoration: none;
                    border-bottom: 1px solid $darkblue;
                }
            }
        }
        .date{
            color: $darkblue;
            font-weight: 600;
            @include media-breakpoint-down(md){
                margin-bottom: 8px;
                margin-top: 26px;
            }
        }
    }
}