.user-profile{
    margin-top: 50px;
    ul{
        li{
            ul{
                li{
                    color: $red;
                }
            }
        }
    }
    form{
        padding: 30px 0;
        .input-container{
            margin-bottom: 30px;
            label{
                transform: translateY(-36px);
                transition: transform .2s linear;
                z-index: 0;
                &.animate-label{
                    transform: translateY(-70px);
                    transition: transform .2s linear;
                }
            }
            input{
                width: 100%;
                height: 50px;
                border: none;
                z-index: 1;
                border-radius: 0;
                background-color: transparent;
                border-bottom: 2px solid $darkblue;
                &:active, &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
            select{
                + label{
                    transform: translateY(-65px);
                }
            }
        }
        .form-group{
            position: relative;
            input.checkbox{
                cursor: pointer;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 4px;
                left: 26%;
                @include media-breakpoint-down(md){
                    left: 40%;
                }
                &:focus, &:active{
                    box-shadow: none;
                    outline: none;
                }
            }
            label[for="id_manual"], #id_manual{
                margin-top: 30px;
            }
            .img-input {
                max-width: 50%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .img-wrapper {
                padding: 15px 10px;
                display: inline-block;
                .btn-wrapper {
                    position: relative;
                    .delete-img {
                        position: absolute;
                        background-color: transparent;
                        right: 4px;
                        top: -7px;
                        opacity: 0.65;
                        border: none;
                    }
                    .delete-img:hover {
                        opacity: 1;
                    }
                    .delete-img:before, .delete-img:after {
                        position: absolute;
                        left: 15px;
                        content: ' ';
                        height: 10px;
                        width: 1.5px;
                        background-color: $black;
                    }
                    .delete-img:before {
                        transform: rotate(45deg);
                    }
                    .delete-img:after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    .btn{
        width: 220px;
        @media (max-width: 992px){
            &.already-user{
                font-size: 12px;
                width: 170px;
                padding: 0;
            }
        }
    }
    .students-container{
        @media (max-width: 992px){
            overflow: scroll;
        }
        table{
            margin-top: 30px;
            td{
                @media (max-width: 992px){
                    font-size: 14px;
                }
                .dropdown-toggle{
                    &::after{
                        right: 10px;
                        top: 20px;
                        position: absolute;
                    }
                }
                .no-tries{
                    cursor: inherit;
                    &::after{
                        content: none;
                    }
                }
                .dropdown-menu{
                    @media (max-width: 992px){
                        max-height: 250px;
                        overflow: scroll;
                    }
                    &.no-tries{
                        display: none;
                    }
                }
            }
        }
    }
    .star{
        font-size: 14px;
        @media (max-width: 992px){
            margin-top: 20px;
        }
    }

    #id_languages {
        li {
            list-style: none;
            &:before {
                height: 0;
                width: 0;
            }
        }
    }
}