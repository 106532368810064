footer{
    padding-top: 65px;
    padding-bottom: 85px;
    height: 275px;
    border-top: 1px solid $grey-d2;
    overflow: hidden;
    position: absolute;
    width: 100%;
    bottom: 0;
    @include media-breakpoint-down(md){
        height: auto;
    }
    svg{
        position: absolute;
        left: -36px;
        top: -10px;
    }
    .footer-title{
        font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
        line-height: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
        font-weight: 600;
        color: $font-color;
    }
    .info-container{
        font-weight: 300;
        margin-top: 30px;
        line-height: 30px;
        color: $font-color;
        span{
            color: $font-color;
            font-weight: 800;
            font-size: 20px;
            line-height: 28px;
            display: block;
        }
    }
}

@media print{
    footer{
        display: none;
    }
}