.page-classindexpage{
    .class-index-page{
        padding-top: 100px;
        padding-bottom: 100px;
        @include media-breakpoint-down(md){
            padding-top: 40px;
        }
        .card-col{
            @media (min-width: 1200px){
                min-width: 326px;
            }
            @media(max-width: 1200px) and (min-width: 768px){
                min-width: 300px;
            }
        }
        .card-container{
            height: 100%;
            border-radius: 16px;
            overflow: hidden;
            background-color: $white;
            margin-bottom: 30px;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
            a{
                text-decoration: none;
                .card{
                    border: none !important;
                }
            }
            .icon-container{
                min-height: 160px;
                @include media-breakpoint-down(sm){
                    min-height: 100px;
                    justify-content: flex-end !important;
                    svg{
                        width: 50%;
                    }
                }
            }
            .text-container{
                padding: 30px;
                color: $font-color;
                @include media-breakpoint-down(sm){
                    padding: 20px;
                }
                h3{
                    margin-bottom: 20px;
                }
                span{
                    padding-bottom: 120px;
                    p{
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
                .btn{
                    color: $font-color;
                    border: 1px solid $darkblue;
                    &:hover{
                        color: $white;
                    }
                }
                .pricing{
                    position: absolute;
                    bottom: 40px;
                    left: 0;
                    .price{
                        font-weight: 680;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}