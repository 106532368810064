.outer-container{
    margin-top: 150px;
    @include media-breakpoint-down(md){
        margin-top: 50px;
    }
}
.payment-view{
    box-shadow: 0 2px 4px 2px rgba(0,0,0,0.15);
    border-radius: 8px;
    padding: 30px;
    .label{
        font-size: 14px;
        margin-bottom: 0;
    }
    p.name, p.ssn{
        font-weight: 600;
    }
    .buyer, .product{
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
    }
    .payment{
        p{
            margin-bottom: 0;
        }
    }
    .btn{
        border: 2px solid $darkblue !important;
    }
}