.module-page {
    background-color: $blue;
    min-height: calc(100vh - 118px);
    svg {
        width: 160px;
        margin: 0 auto;
        display: block;
    }
    .text {
        color: $white;
        width: 332px;
        margin: 0 auto;
        text-align: center;
        a { color: $white;}
        .title {
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
        }
    }
    .timer {
        display: flex;
        flex-direction: column;
        margin: 120px auto 60px;
        width: fit-content;
        .timer-wrapper {
            align-self: center;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 4px solid white;
            z-index: 2;
            justify-self: center;
        }
        .coutndown-wrapper {
            left: 50%;
            transform: translateX(-50%);
        }
        .coutndown-wrapper-time {
            color: $white;
            grid-area: 2;
        }
    }
}