.latest-news-block{
    .pagination-wrapper{
        ul{
            display: flex;
            justify-content: center;
            li{
                margin-right: 8px;
                &::before{
                    content: none !important;
                }
                &.active{
                    border-bottom: 2px solid $font-color;
                }
                @include media-breakpoint-down(md){
                    margin-right: 12px;
                }
            }
        }
    }
}