header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    transform: translateY(0);
    z-index: 1000;
    background-color: $white;
    &.nav-up{
        transform: translateY(-100%);
        transition: transform .3s linear;
    }
    &.nav-down{
        transition: transform .3s linear;
        transform: translateY(0);
    }
    nav.navbar{
        height: 120px;
        @include media-breakpoint-down(md){
            height: 90px;
        }
        .navbar-brand{
            color: $font-color;
            font-family: 'Public Sans', sans-serif;
            font-size: calc(16px + (30 - 16) * ((100vw - 360px) / (1920 - 360))) !important;
            svg{
                margin-right: 17px;
                @include media-breakpoint-between(lg, xl){
                    min-width: 50px;
                }
                @include media-breakpoint-down(md){
                    position: absolute;
                    left: -30px;
                    width: 34px;
                }
            }
            &.green{
                svg{
                    g{
                        g{
                            fill: $green !important;
                        }
                    }
                }
            }
            &.blue{
                svg{
                    g{
                        g{
                            fill: $blue !important;
                        }
                    }
                }
            }
            .custom-logo{
                height: 65px;
                width:auto;
            }
        }
        .login-container{
            margin-right: -1%;
            @include media-breakpoint-down(sm){
                padding-right: 8px;
            }
        }
        .login{
            .authenticated, .not-auth{
                text-decoration: none;
                cursor: pointer;
            }
            .authenticated{
                color: $font-color;
                font-weight: 600;
                font-size: 16px;
                .user-name{
                    margin-right: 36px;
                    position: relative;
                    &::after{
                        content: '';
                        position: absolute;
                        top: 0;
                        height: 20px;
                        width: 1px;
                        background-color: $grey-d2;
                        right: -20px;
                    }
                }
            }
            .not_auth{
                border: 2px solid $darkblue !important;
                &:hover{
                    svg{
                        g>g{
                            fill: $white;
                        }
                    }
                    svg.shopping {
                        path {
                            fill: $white;
                        }
                    }
                }
                svg{
                    margin-right: 8px;
                    margin-top: -4px;
                }
            }
        }
        .mobile-login{
            .user-icon{
                width: 22px;
                height: 24px;
                fill: $font-color;
            }
            a{
                padding: 0;
                border: none;
                margin-top: 0;
                &:hover{
                    background-color: transparent !important;
                }
                .lock-icon{
                    width: 26px;
                    height: 26px;
                    g>g{
                        fill: $font-color;
                    }
                }
                .shopping-icon {
                    width: 26px;
                    height: 26px; 
                    bottom: -2px;
                    @include media-breakpoint-down(sm){
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }
        button{
            &:focus, &:active{
                box-shadow: none !important;
                outline: none !important;
            }
        }
        .navbar-toggler-icon{
            margin-right: -20px;
            .line{
                width: 33px;
                height: 2px;
                background-color: $font-color;
                margin-bottom: 6px;
                border-radius: 2px;
                transform: rotate(0) translate(0);
                transition: width .2s linear, transform .2s linear;
                &:last-child{
                    width: 20px;
                }
            }
            &.open{
                z-index: 10;
                .line{
                    &:first-child{
                        transform: rotate(45deg) translate(6px, 6px);
                        transition: transform .2s linear;
                    }
                    &.middle{
                        width: 33px;
                        transform: rotate(-45deg);
                        transition: all .2s linear;
                    }
                    &:last-child{
                        width: 0;
                        transition: width .2s linear;
                    }
                }
            }
        }
    }
    .nav-row{
        z-index: 10;
        background-color: $white;
        @include media-breakpoint-up(md){
            width: 100vw !important;
        }
        @include media-breakpoint-down(md){
            justify-content: space-between;
        }
    }
    .mobile-menu{
        transform: translateY(-100%);
        position: absolute;
        left: 0;
        z-index: 9;
        transition: transform .6s linear;
        @include media-breakpoint-down(md){
            transition: transform .3s linear;
            transform: translate(100%, 56%);
            max-height: 100vh;
        }
        .card-container{
            width: 100vw;
        }
    }
    body.hamburger-open &{
        .mobile-menu{
            max-height: 100vh;
            transform: translateY(56%);
            transition: transform .6s linear;
            overflow-y: scroll;
            @include media-breakpoint-down(md){
                transition: transform .3s linear;
                transform: translate(0%, 56%);
                overflow: scroll;
                .top-row{
                    width: auto !important;
                }
            }
        }
    }
    .card-container{
        padding-top: 60px;
        padding-bottom: 86px;
        .cards{
            &:nth-child(n+4){
                .card{
                    background-color: $white;
                    h1{
                        margin-top: 30px;
                    }
                    h1, span{
                        color: $font-color !important;
                    }
                }
            }
        }
        a{
            text-decoration: none;
            .card{
                min-height: 270px;
                margin-bottom: 34px;
                padding-left: 40px;
                border-radius: 8px;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
                @include media-breakpoint-down(md){
                    margin-bottom: 15px;
                    min-height: 200px;
                }
                svg{
                    margin-bottom: 30px;
                }
                h1{
                    color: $white;
                    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
                    line-height: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
                    font-style: normal;
                    font-weight: 680;
                    @media (min-width: 1920px){
                        font-size: 24px !important;
                        line-height: 24px !important;
                    }
                }
                span{
                    color: $white;
                    font-style: italic;
                    font-weight: 300;
                }
            }
        }
    }
}

@media print {
    header{
        position: absolute;
    }
}