.page-standardindexpage{
    .card-container{
        a{
            .card{
                height: 270px !important;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                svg{
                    position: absolute;
                    bottom: 117px;
                    right: 20px;
                }
                .title-container{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 102px;
                    padding-left: 40px;
                    h3{
                        color: $font-color;
                        padding-top: 8px;
                    }
                }
            }
        }
    }
}