.page-examindexpage{
    .card{
        height: 196px;
        width: 100%;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
        margin-bottom: 30px;
        position: relative;
        border-radius: 8px;
        background-color: $white;
        overflow: hidden;
        h3{
            color: $font-color;
            font-weight: 600;
        }
        .status{
            height: 62px;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            color: $grey-d4;
        }
    }
}