$white: #FFF;
$font-color-light: #3E474F;
$font-color: #464849;
$font-color-2: #6C6C6C;

$darkblue: #1C5994;
$blue: #167DA5;
$green: #48b5a8;
$grey-l1: #f0f4f7;
$grey: #E2E9EE;
$grey-d2: #C2CDD5;
$grey-d3: #C1CCD4;
$grey-d4: #6A727A;
$grey-d5: #A3ADB3;

$grey-15: #e2e9ee26;
$darkblue-15: #1c599426;
$blue-15: #167da526;
$green-15: #48b5a826;

.bg-grey{
    background-color: $grey;
}
.bg-darkblue{
    background-color: $darkblue;
}
.bg-blue{
    background-color: $blue;
}
.bg-green{
    background-color: $green;
}


.bg-grey-15{
    background-color: $grey-15 !important;
}
.bg-darkblue-15{
    background-color: $darkblue-15 !important;
}
.bg-blue-15{
    background-color: $blue-15 !important;
}
.bg-green-15{
    background-color: $green-15 !important;
}