.page-moduleitempage{
    .paragraph-image-block{
        margin-bottom: 80px;
    }
    .image-block{
        .image-text{
            height: 78px;
            &::before{
                content: ''; 
            }
        }
    }
    .paragraph-block{
        p{
            font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
            line-height: calc(24px + (34 - 24) * ((100vw - 300px) / (1920 - 300))) !important;
            font-weight: 300;
            color: $font-color-2;
        }
    }
    .module-item-page {
        .title{
            font-weight: 680;
        }
        .rsbtn {
            margin-top: 40px;
            border-radius: 6px;
            border: 2px solid $grey;
            background-color: $white;
            width: fit-content!important;
            margin: 40px auto 0px;
            padding-top: 8px;
            padding-bottom: 4px;
            &:before {
                content: "";
                position: absolute;
                height: 22px;
                left: unset;
                right: 20px;
                //background-image: url('../img/sound-icon.svg');
                width: 30px;
                background-size: contain;
                background-repeat: no-repeat;
                top: 16px;
                z-index: 2;
            }
            &.rsexpanded {
                padding-top: 8px;
                padding-bottom: 40px;
                .rsbtn_play{
                    width: calc(240px + 90px + 50px + 40px);
                    position: relative;
                    padding: 0px;
                    border-radius: 16px;
                }
                .rsbtn_exp {
                    position: absolute;
                    width: 100%;
                    left: 0px;
                    top: 100%;
                    transform: translateY(calc(-100% - 14px));
                    .rsbtn_exp_inner {
                        border: none;
                        width: fit-content;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: transparent;
                        .rs-player-loadindicator {
                            width: 260px;
                            left: 8px !important;
                            top: -24px;
                        }
                        .rsbtn_player_item  {
                            border-left: 1px solid $grey;
                            &:nth-of-type(1) {
                                border: none;
                            }
                        }
                    }
                } 
            }
            .rsbtn_play {
                height: 40px;
                width: 140px;
                border: none;
                background-color: $white;
                &:hover {
                    background: transparent;
                }
                .rsbtn_right {
                    border-left: none;
                }
                .rsbtn_left {
                    margin: 0px 0px 0px 10px;
                }
                .rsbtn_text {
                    &:before {
                        content: none;
                    }
                    .rsbtn_label {
                        font-size: 16px;
                        line-height: 23px;
                        padding: 8px;
                        color: $grey;
                    }
                }
                .rsplay {
                    display: none!important;
                }
            }
            .rsbtn_tooltoggle {
                display: none!important;
            }
        }
        .intro {
            margin: 24px auto;
            .title {
                padding-top: 40px;
                @include media-breakpoint-down(sm) {
                    padding-top: 32px;
                }
            }
            .chapter-container {
                display: flex;
                width: fit-content;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                    grid-template-columns: 1fr auto;
                }
                span {
                    font-size: 17px;
                    letter-spacing: 0.5px;
                    line-height: 23px;
                    &:nth-child(1) {
                        text-transform: uppercase;
                        padding-right: 24px;
                        position: relative;
                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                            padding: 0;
                        }
                        &:before {
                            content: "";
                            height: 70%;
                            width: 1px;
                            background-color: $grey-d2;
                            position: absolute;
                            right: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            @include media-breakpoint-down(sm) {
                                content: none;
                            }
                        }
                    }
                    &:nth-child(2) {
                        padding-left: 24px;
                        @include media-breakpoint-down(sm) {
                            color: rgba($font-color, .8);
                            font-size: 14px;
                            padding: 0px;
                        }
                    }
                }
            }
        }
        .paragraph-block {
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
        .module-navigation {
            max-width: 635px;
            margin: 40px auto;
            height: 110px;
            position: relative;
            display: flex;
            padding: 0px 20px;
            margin-bottom: 80px;
            @include media-breakpoint-down(sm) {
                padding: 0px;
                margin: 30px 0;
            }
            &:before {
                content: "";
                background-color: $darkblue;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                left: 0px;
                top: 0px;
                z-index: -1;
                @include media-breakpoint-down(sm) {
                    border-radius: 0px;
                    width: 100vw;
                    left: -60px;
                }
                @include media-breakpoint-down(xs) {
                    left: -32px;
                }
            }
            a {
                display: block;
                border-radius: 50%;
                position: relative;
                align-self: center;
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &.prev {
                    width: 46px;
                    height: 46px;
                    background-color: rgba($black, .2);
                    svg {
                        width: 12px;
                        path {
                            fill: $white;
                        }
                    }
                }
                &.next {
                    position: absolute;
                    right: 20px;
                    width: 64px;
                    height: 64px;
                    background-color: $white;
                    box-shadow: 0 12px 46px 0 rgba(0,0,0,0.15);
                    svg {
                        width: 16px;
                        path {
                            fill: $blue;
                        }
                    }
                }
            }
            .percentage-completed {
                width: calc(100% - 40px - 46px - 64px - 40px);
                position: absolute;
                left: calc(20px + 46px + 20px);
                height: 8px;
                background-color: $white;
                border-radius: 4px;
                align-self: center;
                .percentage {
                    height: 100%;
                    border-radius: 4px;
                    background-color: $yellow;
                    span {
                        opacity: 0;
                    }
                }
            }
        }
    }
}