.page-standardpage{
    .title-container{
        .subtitle{
            line-height: 34px !important;
            margin-top: 10px;
        }
    }
    .header-image-container{
        max-height: 708px;
        margin-top: 30px;
        margin-bottom: 100px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }
    .text-container{
        margin-top: 40px;
    }
}