.page-exampage{
    .exam-page{
        padding-bottom: 100px;
        background-color: $grey;
        @include media-breakpoint-down(md){
            padding-top: 100px;
        }
        @include media-breakpoint-down(sm){
            padding-bottom: 60px;
        }
        &.green{
            button{
                border-color: $green;
                &:hover{
                    background-color: $green;
                    color: $white;
                }
            }
            .percentage-wrapper{
                background-color: $green;
            }
        }
        &.blue{
            button{
                border-color: $blue;
                &:hover{
                    background-color: $blue;
                    color: $white;
                }
            }
            .percentage-wrapper{
                background-color: $blue;
            }
        }
        &.darkblue{
            button{
                border-color: $darkblue;
                &:hover{
                    background-color: $darkblue;
                    color: $white;
                }
            }
            .percentage-wrapper{
                background-color: $darkblue;
            }
        }
    }
    .exam {
        .exam-title {
            color: $font-color-light;
            margin-top: 100px;
        }
        .status-container{
            margin-bottom: 30px;
        }
        .button {
            margin-top: 0 !important;
            &:hover {
                text-decoration: none;
            }
            &.go-over {
                background-color: $yellow;
            }
            &.re-do, &.start {
                background-color: $white;
                color: $font-color;
            }
            &.onward, &.re-read {
                background-color: rgba($white, .4);
            }
        }
        
        .question-container {
            .question {
                min-height: calc(100vh - 118px);
                &.hidden {
                    display: none;
                }
                .question-wrapper {
                    width: 100%;
                    background-color: $white;
                    margin: 80px auto 0px;
                    border-radius: 16px;
                    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
                    position: relative;
                    @include media-breakpoint-down(sm) {
                        margin: 40px auto;
                    }
                    &.checkbox {
                        padding-bottom: 24px;
                    }
                    &.radio {
                        padding-bottom: 58px;
                    }
                    .title {
                        padding: 24px 32px 16px;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 25px;
                    }
                    .text {
                        padding: 0px 32px 32px;
                        font-size: 16px;
                        line-height: 23px;
                    }
                    .img-container {
                        border-radius: 16px;
                        text-align: center;
                        width: calc(100% - 64px);
                        margin: 0 auto;
                        padding: 24px;
                        margin-bottom: 32px;
                        img {
                            max-width: 100%;
                            max-height: 300px;
                        }
                    }
                    .answer-radio {
                        display: flex;
                        width: 100%;
                        justify-items: center;
                        position: absolute;
                        bottom: 0;
                        .answer-container {
                            padding: 16px 32px;
                            border-top: 1px solid black;
                            width: 100%;
                            text-align: center;
                            position: relative;
                            &:nth-of-type(1) {
                                border-right: 1px solid black;
                            }
                            .radio-container {
                                width: fit-content;
                                margin: auto 0;
                                input {
                                    opacity: 0;
                                    width: 100%;
                                    position: absolute;
                                    height: 100%;
                                    z-index: 5;
                                    left: 0px;
                                    top: 0px;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                    &:checked + label::after{
                                        background-color: $blue;
                                    }
                                    &:checked + label{
                                        font-weight: 600;
                                        &::before{
                                            border: 1px solid $font-color;
                                        }
                                    }
                                }
                                label {
                                    position: relative;
                                    margin-bottom: 0px;
                                    font-size: 20px;
                                    line-height: 23px;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 20px;
                                        height: 20px;
                                        border: 1px solid lightgray;
                                        border-radius: 50%;
                                        background-color: #fff;
                                        top: 50%;
                                        left: -12px;
                                        transform: translate(-100%, -50%);
                                        z-index: 1;
                                    }
                                    &::after {
                                        position: absolute;
                                        content: " ";
                                        width: 12px;
                                        height: 12px;
                                        left: -16px;
                                        top: 50%;
                                        border-radius: 50%;
                                        transform: translate(-100%, -50%);
                                        background-color: white;
                                        z-index: 2;
                                    }
                                }
                            }
                        }
                    }
                    .answer-checkbox {
                        padding: 8px 80px;
                        position: relative;
                        z-index: 0;
                        input {
                            opacity: 0;
                            width: 100%;
                            position: absolute;
                            height: 100%;
                            z-index: 5;
                            left: 0px;
                            top: 0px;
                            &:hover {
                                cursor: pointer;
                            }
                            &:checked + label{
                                svg {
                                    opacity: 1;
                                    transition: opacity .2s ease-out;
                                }
                            }
                            &:checked + label{
                                font-weight: 600;
                                &::before{
                                    border: 1px solid $font-color;
                                }
                            }
                        }
                        label {
                            position: relative;
                            margin-bottom: 0px;
                            font-size: 20px;
                            line-height: 23px;
                            grid-column-start: 2;
                            &::before {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border: 1px solid lightgray;
                                border-radius: 2px;
                                background-color: #fff;
                                top: 0;
                                left: -16px;
                                transform: translateX(-100%);
                                z-index: 1;
                            }
                            @include media-breakpoint-down(md){
                                font-size: 16px;
                            }
                            svg {
                                opacity: 0;
                                position: absolute;
                                width: 12px;
                                transform: translateX(-100%);
                                left: -20px;
                                z-index: 1;
                                transition: opacity .1s ease-out;
                                height: 20px;
                                &>g>g {
                                    fill: $font-color !important;
                                }
                            }
                        }
                    }

                    
                }
                .percentage-wrapper {
                    width: 100%;
                    margin: 40px auto 80px;
                    position: relative;
                    height: 113px;
                    display: flex;
                    border-radius: 16px;
                    justify-content: space-between;
                    padding: 0px 20px;
                    @include media-breakpoint-down(sm) {
                        margin: 0px;
                    }
                    &:before {
                        content: "";
                        background-color: $blue;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 16px;
                        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                        left: 0px;
                        top: 0px;
                        z-index: -1;
                        @include media-breakpoint-down(sm) {
                            border-radius: 0px;
                            width: 100vw;
                            left: -60px;
                        }
                        @include media-breakpoint-down(xs) {
                            left: -32px;
                        }
                    }
                    div {
                        display: block;
                        border-radius: 50%;
                        position: relative;
                        align-self: center;
                        svg {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        &.prev {
                            cursor:pointer;
                            width: 46px;
                            height: 46px;
                            background-color: rgba($black, .2);
                            svg {
                                width: 12px;
                                path {
                                    fill: $white;
                                }
                            }
                        }
                        &.next, &.finish {
                            cursor:pointer;
                            width: 64px;
                            height: 64px;
                            background-color: $white;
                            box-shadow: 0 12px 46px 0 rgba(0,0,0,0.15);
                            position: absolute;
                            right: 20px;
                            svg {
                                width: 16px;
                                path {
                                    fill: $blue;
                                }
                            }
                        }
                    }
                    .percentage-completed {
                        width: calc(100% - 40px - 46px - 64px - 40px);
                        height: 8px;
                        background-color: $white;
                        border-radius: 4px;
                        position: absolute;
                        left: calc(20px + 46px + 20px);
                        .percentage {
                            height: 100%;
                            border-radius: 4px;
                            background-color: $yellow;
                            span {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
    
        .results-overview {
            min-height: 60vh;
            .results-wrapper {
                background-color: $green;
                padding: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .grade-percentage {
                    margin-bottom: 20px;
                    background-color: $white;
                    width: 60px;
                    height: 180px;
                    position: relative;
                    div {
                        position: absolute;
                        width: 100%;
                        bottom: 0px;
                        &.min-grade {
                            background-color: transparent;
                            &:after {
                                content: "";
                                position: absolute;
                                top: 0px;
                                left: 50%;
                                transform: translateX(-50%);
                                height: 100%;
                                width: calc(100% + 40px);
                                border-top: dashed black 2px;
                                z-index: 2;
                            }
                        }
                        &.grade {
                            background-color: $yellow;
                        }
                    }
                }
                .grades {
                    text-align: center;
                    color: $white;
                    padding-bottom: 40px;
                    div {
                        &:nth-of-type(1) {
                            font-size: 20px;
                            line-height: 24px;
                            font-weight: 600;
                        }
                        &:nth-of-type(2) {
                            font-size: 14px;
                            line-height: 16px;
                            padding-top: 4px;
                        }
                    }
                }
                .button {
                    margin-bottom: 20px;
                }
            }
        }
    
        .all-results {
            padding: 40px 0px;
            @include media-breakpoint-down(sm) {
                padding: 40px 0px 0px;
            }
            .results-container {
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
                border-radius: 16px;
                background-color: white;
                position: relative;
                width: 100%;
                margin-bottom: 40px;
                label{
                    font-size: 20px;
                }
                .title {
                    font-size: 22px;
                    font-weight: bold;
                    line-height: 25px;
                    padding: 24px 32px 0px;
                    margin-bottom: 32px;
                }
                .true-false {
                    display: block;
                    width: fit-content;
                    position: absolute;
                    top: 24px;
                    right: 32px;
                }
                .img-container {    
                    border-radius: 16px;
                    text-align: center;
                    width: calc(100% - 64px);
                    margin: 0 auto;
                    padding: 24px;
                    margin-bottom: 32px;
                    img {
                        max-width: 100%;
                        max-height: 300px;
                    }
                }
                .question {
                    padding: 0px 32px;
                }
                .answers-container {
                    font-size: 20px !important;
                    .incorrect-option, .correct-option{
                        margin-top: -0.2rem;
                    }
                    .incorrect-option{
                        position: relative;
                        .x{
                            cursor: help;
                            position: absolute;
                            left: -70px;
                            top: -7px;
                            width: 34px;
                            height: 34px;
                            z-index: 100;
                            &:hover{
                                &::after{
                                    content: 'Þetta svar er rangt';
                                    position: absolute;
                                    top: -28px;
                                    left: -136px;
                                    font-size: 12px;
                                    color: $font-color;
                                    background-color: $white;
                                    box-shadow: 0 0 7px $font-color;
                                    border-radius: 6px;
                                    padding: 12px;
                                    z-index: 1;
                                    @include media-breakpoint-down(sm){
                                        left: 30px;
                                        min-width: 200px;
                                        top: 8px;
                                    }
                                }
                            }
                            span{
                                width: 3px;
                                margin-top: 2px;
                                height: 20px;
                                background-color: $red;
                                position: absolute;
                                &:first-child{
                                    transform: rotate(45deg) translate(14px, -2px);
                                }
                                &:last-child{
                                    transform: rotate(-45deg) translate(2px, 14px);
                                }
                            }
                        }
                        .dots{
                            display: flex;
                            position: absolute;
                            left: -64px;
                            top: 25px;
                            span{
                                margin-right: 3px;
                                width: 3px;
                                height: 3px;
                                background-color: $font-color;
                                border-radius: 50%;
                                box-shadow: inset 0px 0px 2px 0px $white;
                                animation: pulsate 1s linear infinite;
                            }
                        }
                        @-webkit-keyframes pulsate {
                            0%   { box-shadow: inset 0px 0px 0px 0px $white;}
                            50%  { box-shadow: inset 0px 0px 2px 0px $white; }
                            100% { box-shadow: inset 0px 0px 0px 0px $white; }
                        }
                    }
                    &.checkbox {
                        padding-bottom: 32px;
                        &.incorrect {
                            .answer {
                                .correct-option {
                                    grid-column-start: 2;
                                }
                            }
                        }
                        &.correct {
                            .answer {
                                &.correct {
                                    input {
                                        &:checked + label {
                                            svg {
                                                display: block;
                                                &>g>g{
                                                    fill: $font-color;
                                                }
                                            }
                                        }
                                    }
                                    label {
                                        color: $font-color;
                                        svg {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        .answer {
                            display: flex;
                            padding: 8px 80px;
                            position: relative;
                            input {
                                opacity: 0;
                                width: 100%;
                                position: absolute;
                                height: 100%;
                                z-index: 5;
                                left: 0px;
                                top: 0px;
                                &:hover {
                                    cursor: pointer;
                                }
                                &:checked + label{
                                    &::before{
                                        border: 1px solid $font-color;
                                    }
                                }
                            }
                            label {
                                position: relative;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: 1px solid lightgray;
                                    border-radius: 2px;
                                    background-color: #fff;
                                    top: 0px;
                                    left: -16px;
                                    transform: translateX(-100%);
                                    z-index: 1;
                                }
                                svg {
                                    position: absolute;
                                    left: -26px;
                                    transform: translateX(-50%);
                                    width: 12px;
                                    z-index: 1;
                                    height: 20px;
                                    top: 0px;
                                    &>g>g {
                                        fill: $font-color;
                                    }
                                }
                            }
                            .option-correct {
                                grid-column-start: 2;
                            }
                        }
                    }
                    &.radio {
                        .answer {
                            display: flex;
                            width: 100%;
                            margin-top: 32px;
                            bottom: 0;
                            .answer-container {
                                padding: 16px 32px;
                                border-top: 1px solid black;
                                width: 100%;
                                text-align: center;
                                position: relative;
                                &:nth-of-type(1) {
                                    border-right: 1px solid black;
                                }
                                .radio-container {
                                    input {
                                        opacity: 0;
                                        width: 100%;
                                        position: absolute;
                                        height: 100%;
                                        z-index: 5;
                                        left: 0px;
                                        top: 0px;
                                        &:hover {
                                            cursor: pointer;
                                        }
                                        &:checked + label::after{
                                            background-color: $font-color;
                                        }
                                        &:checked + label{
                                            font-weight: 600;
                                            &::before{
                                                border: 1px solid $font-color;
                                            }
                                        }
                                    }
                                    label {
                                        position: relative;
                                        margin-bottom: 0px;
                                        font-size: 16px;
                                        &::before {
                                            content: "";
                                            position: absolute;
                                            width: 20px;
                                            height: 20px;
                                            border: 1px solid lightgray;
                                            border-radius: 50%;
                                            background-color: #fff;
                                            top: 50%;
                                            left: -12px;
                                            transform: translate(-100%, -50%);
                                            z-index: 1;
                                        }
                                        &::after {
                                            position: absolute;
                                            content: " ";
                                            width: 12px;
                                            height: 12px;
                                            left: -16px;
                                            top: 50%;
                                            border-radius: 50%;
                                            transform: translate(-100%, -50%);
                                            background-color: white;
                                            z-index: 2;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .answer{
                        label{
                            width: 0;
                            font-size: 16px;
                        }
                    }
                }
            }
            .results-overview-navigation {
                display: flex;
                height: 126px;
                padding: 0px 20px;
                position: relative;
                width: 100%;
                @include media-breakpoint-down(xs) {
                    width: 100vw;
                    padding: 0px 32px;
                }
                &:before {
                    content: "";
                    background-color: $blue;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                    left: 0px;
                    top: 0px;
                    z-index: -1;
                    @include media-breakpoint-down(sm) {
                        border-radius: 0px;
                        width: 100vw;
                        left: -60px;
                    }
                    @include media-breakpoint-down(xs) {
                        left: 0px;
                    }
                }
                .button {
                    &.prev {
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        background-color: rgba($black, .2);
                        align-self: center;
                        border: none;
                        position: relative;
                        svg {
                            width: 12px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &.re-do {
                        width: 240px;
                        height: 64px;
                        background-color: $yellow;
                        border-radius: 35px;
                        color: $white;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 21px;
                        align-self: center;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        @include media-breakpoint-down(xs) {
                            position: unset;
                            left: unset;
                            transform: translateX(0px);
                            justify-self: end;
                        }
                    }
                }
            }
        }
    
        &.start-exam {
            height: calc(100vh - 118px);
            padding: 40px 0px;
            @include media-breakpoint-down(sm) {
                padding: 0px 0px 40px;
            }
            .intro {
                display: flex;
                align-items: center;
                flex-direction: column;
                .exam-title {
                    position: unset;
                    transform: translate(0, 0);
                    margin-bottom: 40px;
                }
                .intro-icon {
                    height: 100px;
                    width: 100px;
                    border-radius: 50%;
                    border: 8px $white solid;
                    position: relative;
                    box-sizing: content-box;
                    svg {
                        width: 50px;
                        height: auto;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        &>g>g {
                            fill: $white;
                        }
                    }
                }
                .intro-description {
                    color: $white;
                    font-size: 28px;
                    font-weight: bold;
                    line-height: 25px;
                    margin: 32px 0px;
                }
            }
            .start-exam-container {
                .text {
    
                    color: white;
                    font-size: 18px;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 60px;
                }
                .button {
                    margin: 0px auto 24px;
                }
            }
        }
    }
}